import * as React from "react"

import { graphql, useStaticQuery } from 'gatsby'

import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import BackgroundImage from "gatsby-background-image"
import { Helmet } from "react-helmet"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Fade } from "react-awesome-reveal";

import favicon from './../images/favicon.png'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Card from 'react-bootstrap/Card'

import 'bootstrap/dist/css/bootstrap.min.css';
import './../style/custom.css';
import { Col, Row } from "react-bootstrap"

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay  } from 'swiper/core'
import 'swiper/swiper.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';

SwiperCore.use([Autoplay])

config.autoAddCss = false; /* eslint-disable import/first */


const IndexPage = () => {

	const data = useStaticQuery(
		graphql`
		  query {
			desktop: file(relativePath: { eq: "hero-black.jpg" }) {
			  childImageSharp {
				fluid(quality: 95, maxWidth: 1300) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}
		  }
		`
	  )
	
	const imageData = data.desktop.childImageSharp.fluid

	function handleClickWhatsapp() {
		window.gtag("event", "conversion", { send_to: ["AW-334518876/A-tgCPOHm90CENy0wZ8B"]})
	}
	function handleClickCall() {
		window.gtag("event", "conversion", { send_to: ["AW-334518876/Nh2BCKfYxN0CENy0wZ8B"]})
	}


  	return (
		<>
			<Helmet>
				<title>OtoBattery.id - Gratis Antar & Pasang Aki ke Lokasi Anda</title>
				<link rel="icon" type="image/png" href={favicon} sizes="16x16" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
			</Helmet>
			<div className='box'>
				<Container id='home' fluid='true' className='bg-white min-vh-100'>
					<Navbar className='d-none d-lg-block' bg="bg-white" expand="lg">
						<div className='col'>
							<Navbar.Brand className='col-12 mr-0 mt-5 pb-5 text-center border-bottom' href="#home"><StaticImage layout="constrained" width={350} src="../images/logo.png" alt="A Logo" loading='{ eager }' /></Navbar.Brand>
							<div className='row align-items-center pt-3 pb-3'>
								<div className='col contact'>
									<p className='mb-0 text-center text-lg-left red-700'>081290565116</p>
								</div>
								<div className='col-md-12 col-lg text-center'>
									<Navbar.Toggle className='navbar-toggler-lg' aria-controls="basic-navbar-nav" />
									<Navbar.Collapse>
										<Nav className="col-12 justify-content-end pr-0">
											<AnchorLink className='nav-link' to='/#home'>Home</AnchorLink>
											<AnchorLink className='nav-link' to='/#testimoni'>Testimoni</AnchorLink>
											<AnchorLink className='nav-link' to='/#produk'>Produk</AnchorLink>
											<AnchorLink className='nav-link' to='/#hubungi'>Hubungi</AnchorLink>
										</Nav>
									</Navbar.Collapse>
								</div>
							</div>
						</div>
					</Navbar>

					<Navbar className='d-lg-none' bg="bg-white" expand="lg">
						<Navbar.Brand href="#"><StaticImage layout="constrained" width={175} src="../images/logo.png" alt="A Logo" loading='{ eager }' /></Navbar.Brand>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse>
							<Nav>
								<Nav.Link><AnchorLink to='/#home'>Home</AnchorLink></Nav.Link>
								<Nav.Link><AnchorLink to='/#testimoni'>Testimoni</AnchorLink></Nav.Link>
								<Nav.Link><AnchorLink to='/#produk'>Produk</AnchorLink></Nav.Link>
								<Nav.Link><AnchorLink to='/#hubungi'>Hubungi</AnchorLink></Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Navbar>

					<Container fluid>
							<BackgroundImage
								Tag="section"
								className='hero'
								fluid={imageData}
								backgroundColor={`#000`}
								>
								<div className='d-flex align-items-center h-100'>
									<Container>
									<h2 className='text-white text-center'><strong>Solusi cepat kebutuhan aki kendaraan anda</strong></h2>
									<p className='text-white text-center'><strong>Silahkan hubungi kami</strong></p>
									<div className='text-center'>
										<OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281290565116&text=Halo%2C oto-battery.id" target="_blank" rel="noreferrer" type="button" className="btn btn-hero bg-success text-white m-2">
											<FontAwesomeIcon className='w-5 h-5 mr-2' icon={faWhatsapp} />
											<span>Whatsapp</span>
										</OutboundLink>
									
										<OutboundLink onClick={handleClickCall} href='tel:+6281290565116' type="button" className="btn btn-hero bg-red-700">
										<FontAwesomeIcon className='w-4 h-4 mr-2' icon={faPhone} rotation={90}/>
											<span>Telepon</span>
										</OutboundLink>
									</div>
									</Container>
								</div>
							</BackgroundImage>
					</Container>

					<Container>
						<Col>
							<Fade triggerOnce='true'>
								<p className='my-5'>
									<strong>Oto-Battery</strong> merupakan bisnis penjualan accu kendaraan secara online dengan memberikan layanan berupa gratis jasa antar dan pasang accu, serta layanan konsultasi mengenai accu mobil Anda. 
									<strong>Oto-Battery</strong> menjual tiga jenis accu, yaitu Accu Basah (Conventional) , Accu Hybrid dan Accu Kering (Maintenance Free). <strong>Oto-Battery</strong> menyediakan berbagai macam merk accu dengan segala tipe, seperti Varta, GS Astra, Amaron, Incoe, Bosch, Panasonic, Solite, Faster, Yuasa, Furukawa Battery, Delkor dan Massiv. 
								</p>
							</Fade>
						</Col>
					</Container>
					
					<Container id='service'>
						<h2 className='text-center font-weight-bold red-700'>Service yang kami berikan</h2>
							<Col className='mb-3 pb-3 border-bottom'>
								<Fade triggerOnce='true'>
									<Card className='border-0'>
										<Card.Body>
											<Row className='d-flex align-items-center'>
												<Col sm={12} lg={4} className='service-image text-center'>
													<StaticImage layout="constrained" height={125} src="../images/delivery-time.png" alt="A Logo" loading='{ eager }' />
												</Col>
												<Col sm={12} lg={8} className='service-detail text-sm-center mt-sm-4 mt-lg-0 text-lg-left'>
													<strong>Jaminan Antar Cepat</strong><br/>
													Prioritas kami adalah memberikan layanan antar cepat untuk memenuhi kebutuhan accu mobil Anda.
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Fade>
							</Col>

							<Col className='mb-3 pb-3 border-bottom'>
								<Fade triggerOnce='true'>
									<Card className='border-0 b'>
										<Card.Body>
											<Row className='d-flex align-items-center'>
												<Col sm={12} lg={4} className='service-image text-center'>
													<StaticImage layout="constrained" height={125} src="../images/delivery-cost.png" alt="A Logo" loading='{ eager }' />
												</Col>
												<Col sm={12} lg={8} className='service-detail text-sm-center mt-sm-4 mt-lg-0 text-lg-left'>
													<strong>Gratis Antar dan Pasang</strong><br/>
													Nikmati layanan antar dan pasang gratis dari Oto-Battery. Silahkan tunggu ditempat, biar mekanik handal kami yang antar dan pasang accu mobil Anda.
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Fade>
							</Col>

							<Col className='mb-3 pb-3 border-bottom'>
								<Fade triggerOnce='true'>
									<Card className='border-0'>
										<Card.Body>
											<Row className='d-flex align-items-center'>
												<Col sm={12} lg={4} className='service-image text-center'>
													<StaticImage layout="constrained" height={125} src="../images/original.png" alt="A Logo" loading='{ eager }' />
												</Col>
												<Col sm={12} lg={8} className='service-detail text-sm-center mt-sm-4 mt-lg-0 text-lg-left'>
													<strong>Original dan Bergaransi Resmi</strong><br/>
													Oto-Battery hanya menjual accu original dan berkualitas serta bergaransi resmi hingga 1 tahun.
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Fade>
							</Col>

							<Col className='mb-3 pb-3 border-bottom'>
								<Fade triggerOnce='true'>
									<Card className='border-0'>
										<Card.Body>
											<Row className='d-flex align-items-center'>
												<Col sm={12} lg={4} className='service-image text-center'>
													<StaticImage layout="constrained" height={125} src="../images/trade-in.png" alt="A Logo" loading='{ eager }' />
												</Col>
												<Col sm={12} lg={8} className='service-detail text-sm-center mt-sm-4 mt-lg-0 text-lg-left'>
													<strong>Promo Menarik</strong><br/>
													Nikmati promo menarik dari Oto-Battery, berupa Diskon Tukar Tambah.
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Fade>
							</Col>

							<Col>
								<Fade triggerOnce='true'>
									<Card className='border-0'>
										<Card.Body>
											<Row className='d-flex align-items-center'>
												<Col sm={12} lg={4} className='service-image text-center'>
													<StaticImage layout="constrained" height={125} src="../images/technician.png" alt="A Logo" loading='{ eager }' />
												</Col>
												<Col sm={12} lg={8} className='service-detail text-sm-center mt-sm-4 mt-lg-0 text-lg-left'>
													<strong>Berpengalaman</strong><br/>
													Oto-Battery berpengalaman dalam dunia accu. Percayakan masalah accu mobil Anda kepada Oto-Battery. Customer Service dan Mekanik kami siap membantu permasalahan accu mobil Anda.
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Fade>
							</Col>
					</Container>

					<div id='testimoni' className='col my-5'>
						<Fade triggerOnce='true'>
							<h2 className='text-center red-700 mb-5'><strong>Testimoni Pembeli</strong></h2>
							<Swiper
							slidesPerView={1}
							centeredSlides={true} 
							autoplay={{
								"delay": 4000,
								"disableOnInteraction": false
							}} 
							>
								<SwiperSlide>
									<div>
										<StaticImage layout={'fixed'} height={128} width={128} src="../images/testimoni-1.png" alt="testimoni-image" className='m-auto' loading='{ eager }'/>
										<Col xs={8} className='mx-auto'>
											<p className='text-center my-1'><strong>Oto-Battery</strong> menjadi solusi paling pas apabila aki mobil kita soak di jalan. Cepat responnya dan cepat dalam pengiriman. Good Job <strong>Oto-Battery</strong>!</p>
										</Col>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div>
										<StaticImage layout={'fixed'} height={128} width={128} src="../images/testimoni-2.png" alt="lia" className='w-32 m-auto' loading='{ eager }'/>
										<Col xs={8} className='mx-auto'>
											<p className='text-center my-1'>Pelayanan terbaik deh <strong>Oto-Battery</strong>, CS nya tanggap dan ramah, mekaniknya juga handal. Bintang lima pokoknya untuk <strong>Oto-Battery</strong>. Sukses selalu!</p>
										</Col>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div>
										<StaticImage layout={'fixed'} height={128} width={128} src="../images/testimoni-3.png" alt="riki" className='w-32 m-auto' loading='{ eager }'/>
										<Col xs={8} className='mx-auto'>
											<p className='text-center my-1'>Saya driver online sangat terbantu dengan promo yang ditawarkan oleh <strong>Oto-Battery</strong>. <strong>Oto-Battery</strong> banyak memberikan promo-promo menarik. Harga bersaing namun kualitas terjamin, dan tentunya garansi resmi. Kereen !</p>
										</Col>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div>
										<StaticImage layout={'fixed'} height={128} width={128} src="../images/testimoni-4.png" alt="riki" className='w-32 m-auto' loading='{ eager }'/>
										<Col xs={8} className='mx-auto'>
											<p className='text-center my-1'>Jaman sekarang ganti aki semudah angkat telpon. Tinggal telpon, aki langsung diantar dan dipasangkan, gratis pula. Thanks <strong>Oto-Battery</strong>, membuat hidup lebih mudah.</p>
										</Col>
									</div>
								</SwiperSlide>
							</Swiper>
						</Fade>
					</div>

					<div id='produk' className='col my-5'>
						<Fade triggerOnce cascade>
							<h2 className='text-center my-5 red-700'><strong>Produk Kami</strong></h2>

							<div className='row'>
								<Col xs={12} lg={4} className='product mb-3 d-flex align-items-stretch'>
									<Card className='shadow border-0'>
										<Card.Body>
											<Col>
												<center><StaticImage width={300} src="../images/aki-mf.png" alt="A Logo" loading='{ eager }' /></center>
											</Col>
											<Col>
												<h3 className='product-text text-center my-2'><strong>Aki Mobil<br/>Tipe Maintenance Free</strong></h3>
												<center>Keunggulan Aki Maintenance Free (Aki Kering) yaitu didesain khusus untuk iklim tropis Indonesia. Minim Perawatan karena tidak perlu lagi mengecek dan menambah air aki, sehingga menghemat waktu dan biaya perawatan. Kerusakan aki karena kelalaian mengecek dan menambah air aki menjadi tidak ada.</center>
											</Col>
										</Card.Body>
									</Card>
								</Col>
								<Col xs={12} lg={4} className='product mb-3 d-flex align-items-stretch'>
									<Card className='shadow border-0'>
										<Card.Body>
											<Col>
												<center><StaticImage width={300} src="../images/aki-hybrid.png" alt="A Logo" loading='{ eager }' /></center>
											</Col>
											<Col>
												<h3 className='product-text text-center my-2'><strong>Aki Mobil<br/>Tipe Hybrid</strong></h3>
												<center>Keunggulan Aki Hybrid yaitu menggunakan teknologi Hybrid sehingga penguapan air aki lebih rendah dan penambahan air aki lebih lama 4 sampai 5 kali dibandingkan aki konvensional. Siap pakai aki sudah terisi accu zuur standar pabrik dan sudah terisi setrum, siap langsung dipakai.</center>
											</Col>
										</Card.Body>
									</Card>
								</Col>
								<Col xs={12} lg={4} className='product mb-3 d-flex align-items-stretch'>
									<Card className='shadow border-0'>
										<Card.Body>
											<Col>
												<center><StaticImage width={300} src="../images/aki-basah.png" alt="A Logo" loading='{ eager }' /></center>
											</Col>
											<Col>
												<h3 className='product-text text-center my-2'><strong>Aki Mobil<br/>Tipe Conventional</strong></h3>
												<center>Keunggulan Aki Conventional (Aki Basah) yaitu Aki Conventional untuk mobil dengan kualitas terpercaya, kuat dan awet. Special Quick Activation yaitu aki langsung bertenaga optimal segera setelah diisi accu zuur. </center>
											</Col>
										</Card.Body>
									</Card>
								</Col>
							</div>

							<Col className='my-3'>
								<Swiper
								slidesPerView={3}
								spaceBetween={50}
								autoplay={{
									"delay": 2000,
									"disableOnInteraction": false
								}}
								breakpoints={{
									1024: {
									slidesPerView: 5,
									},
								}}
								>
									<SwiperSlide>
										<StaticImage src="../images/amaron.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/bosch.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/delkor.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/furukawa.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/gforce.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/gsastra.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/incoe.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/massiv.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/panasonic.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/rocket.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/solite.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/varta.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
									<SwiperSlide>
										<StaticImage src="../images/yuasa.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
									</SwiperSlide>
								</Swiper>
							</Col>
						</Fade>
					</div>

					<div id='hubungi' className='col'>
						<h2 className='text-center red-700'><strong>Mau Info Lebih Lanjut ?</strong></h2>
						<p className='text-center my-4'><FontAwesomeIcon className='mr-2 red-700' icon={faPhone} rotation={90}/> <span className='red-700'>+6281290565116</span><br/><FontAwesomeIcon className='mr-2 red-700' icon={faEnvelope}/> <a href="mailto:otobattery@gmail.com">otobattery@gmail.com</a></p>
					</div>

					<div className='col mt-5'>
						<center>aki | tukar tambah aki | tukang accu mobil | toko aki terdekat | toko aki mobil jakarta | toko aki mobil |toko aki mobil | toko aki 24 jam | toko aki | toko accu mobil | jual aki mobil | jual aki murah | jual aki jakarta |jual accu mobil | jual accu | ganti aki mobil terdekat | delivery aki mobil | bengkel aki mobil | beli aki mobil |aki mobil panggilan | aki mobil murah | aki mobil 24 jam | aki mobil | accu terdekat | accu mobil | aki kering mobil</center>
					</div>

					<div className='col mt-5 pb-3'>
						<p className='text-center'>&copy; 2021 <a href="https://www.oto-battery.id">Oto-Battery.id</a> All Right Reserved<br/></p>
					</div>
				</Container>
			</div>
			<OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281290565116&text=Halo%2C oto-battery.id" className="bg-success text-white wa-float" target="_blank" rel="noreferrer">
				<FontAwesomeIcon className='wa-my-float' icon={faWhatsapp} />
			</OutboundLink>
			<OutboundLink onClick={handleClickCall} href="tel:+6281290565116" className="bg-red-700 call-float" rel="noreferrer">
				<FontAwesomeIcon className='call-my-float' icon={faPhone} />
			</OutboundLink>
		</>
  	)
}

export default IndexPage
